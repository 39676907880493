var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"confirm-loading":_vm.loading,"title":"宿主机关联","visible":_vm.visible,"width":720},on:{"cancel":_vm.reset,"ok":_vm.ok}},[_c('a-form-model',{ref:"form",attrs:{"label-col":{
      span: 4,
      style: {
        textAlign: 'left'
      }
    },"model":_vm.form,"rules":_vm.rules,"wrapper-col":{
      span: 20
    }}},[_c('a-form-model-item',{staticStyle:{"margin-bottom":"0"},attrs:{"label":"物理机","prop":"server"}},[_c('source-select',{attrs:{"source-type":"server"},model:{value:(_vm.form.server),callback:function ($$v) {_vm.$set(_vm.form, "server", $$v)},expression:"form.server"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }