<template>
  <a-modal
    :confirm-loading="loading"
    title="虚拟主机关联"
    :visible="visible"
    :width="720"
    @cancel="reset"
    @ok="ok"
  >
    <a-form-model
      ref="form"
      :label-col="{
        span: 4,
        style: {
          textAlign: 'left'
        }
      }"
      :model="form"
      :rules="rules"
      :wrapper-col="{
        span: 20
      }"
    >
      <a-form-model-item label="操作系统" prop="os" style="margin-bottom: 0">
        <source-select v-model="form.os" source-type="os"></source-select>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { updateHypervisorVirtualMachine } from '@/api/hypervisor'
import SourceSelect from '@/components/select/SourceSelect'

export default {
  name: 'LinkModal',
  components: {
    SourceSelect
  },
  props: {
    hypervisorId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      visible: false,
      form: {
        id: '',
        os: undefined
      },
      rules: {
        os: [
          {
            message: '请选择操作系统',
            required: true,
            trigger: 'change'
          }
        ]
      }
    }
  },
  methods: {
    show (virtualMachine) {
      this.form.id = virtualMachine.id
      this.form.os = virtualMachine.os
      this.visible = true
    },
    reset () {
      this.form = {
        id: '',
        os: undefined
      }
      this.$refs.form.resetFields()
      this.visible = false
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const params = {
            virtual_machine_id: this.form.id,
            os_id: this.form.os.id
          }
          this.loading = true
          updateHypervisorVirtualMachine(this.hypervisorId, params)
            .then(res => {
              this.$message.success(res.message)
              this.$emit('ok')
              this.reset()
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    }
  }
}
</script>
