<template>
  <a
    @click="e => $emit('click', e)"
  >
    <template v-if="showText">
      <a-icon type="reload" class="icon"></a-icon>
      <span class="text">刷新</span>
    </template>
    <a-tooltip title="刷新" v-else>
      <a-icon type="reload" class="icon"></a-icon>
    </a-tooltip>
  </a>
</template>

<script>
export default {
  name: 'ReloadButton',
  props: {
    showText: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped lang="less">
a {
  color: #575757;
  user-select: none;

  &:hover {
    color: #0880ff;
  }

  .icon {
    font-size: 13px;
    vertical-align: text-bottom;
    padding-bottom: 1px;
  }
  .text {
    font-size: 12px;
    margin-left: 4px;
  }
}
</style>
