<template>
  <div class="hypervisor-dashboard-detail">
    <a-spin :spinning="spinning">
      <a-tabs v-model="activeTab" type="card" v-if="!spinning">
        <a-tab-pane key="overview" tab="总览">
          <a-row :gutter="16" style="margin-top: 16px">
            <a-col
              :sm="24"
              :md="12"
              :lg="6"
              v-for="item in overviewCountList"
              :key="item.name"
              style="margin-bottom: 16px"
            >
              <count-card
                :item="{
                  name: $t(`hypervisor.${item.name}`),
                  key: item.name,
                  value: item.value
                }"
                @troggleTab="v => (activeTab = v)"
                style="cursor: pointer; box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 8px;"
              ></count-card>
            </a-col>
          </a-row>

          <a-row :gutter="16">
            <!-- 基本概况 -->
            <a-col :md="24" :xl="10" style="margin-bottom: 16px">
              <div class="module-container" style="height: 370px">
                <div class="module-header" style="margin-bottom: 24px">基本概况</div>

                <div class="base-info">
                  <div class="base-container cpu-container">
                    <div>
                      <hypervisor-unit-icon icon="cpu" style="font-size: 32px;"></hypervisor-unit-icon>
                      <p class="card-title">CPU</p>
                    </div>
                    <div class="overview-base-card-container">
                      <p class="value">{{ statistic.cpu.usage | formatData}} %</p>
                      <p class="title">使用率</p>
                    </div>
                    <div style="width: 1px; height: 56px; background: #D4D7DD;"></div>
                    <div class="overview-base-card-container">
                      <p class="value">{{ statistic.cpu.core | formatData }}</p>
                      <p class="title">核数</p>
                    </div>
                    <div style="width: 1px; height: 56px; background: #D4D7DD"></div>
                    <div class="overview-base-card-container">
                      <p class="value">{{ statistic.cpu.thread | formatData }}</p>
                      <p class="title">线程数</p>
                    </div>
                  </div>

                  <div class="base-container memory-container">
                    <div>
                      <hypervisor-unit-icon icon="memory" style="font-size: 32px;"></hypervisor-unit-icon>
                      <p class="card-title">内存</p>
                    </div>
                    <div class="overview-base-card-container">
                      <p class="value">{{ statistic.memory.usage | formatData}} %</p>
                      <p class="title">使用率</p>
                    </div>
                    <div style="width: 1px; height: 56px; background: #D4D7DD;"></div>
                    <div class="overview-base-card-container">
                      <p class="value">{{ statistic.memory.used | formatData}} GB</p>
                      <p class="title">已使用</p>
                    </div>
                    <div style="width: 1px; height: 56px; background: #D4D7DD;"></div>
                    <div class="overview-base-card-container">
                      <p class="value">{{ statistic.memory.total | formatData}} GB</p>
                      <p class="title">总容量</p>
                    </div>
                  </div>

                  <div class="base-container storage-container">
                    <div>
                      <hypervisor-unit-icon icon="storage" style="font-size: 32px;"></hypervisor-unit-icon>
                      <p class="card-title">存储</p>
                    </div>
                    <div class="overview-base-card-container">
                      <p class="value">{{ statistic.storage.usage | formatData}} %</p>
                      <p class="title">使用率</p>
                    </div>
                    <div style="width: 1px; height: 56px; background: #D4D7DD;"></div>
                    <div class="overview-base-card-container">
                      <p class="value">{{ statistic.storage.used | formatData}} GB</p>
                      <p class="title">已使用</p>
                    </div>
                    <div style="width: 1px; height: 56px; background: #D4D7DD;"></div>
                    <div class="overview-base-card-container">
                      <p class="value">{{ statistic.storage.total | formatData}} GB</p>
                      <p class="title">总容量</p>
                    </div>
                  </div>
                </div>
              </div>
            </a-col>
            <!-- 告警中心 -->
            <a-col :md="24" :xl="14" style="margin-bottom: 16px">
              <div class="module-container" style="height: 370px">
                <div class="module-header" style="margin-bottom: 24px">告警中心</div>
                <div style="position: absolute; right: 20px; top: 17px">
                  <span
                    style="
                      font-size: 14px;
                      color: rgba(0, 0, 0, 0.5);
                      line-height: 20px;
                      margin-right: 8px
                    "
                  >
                    告警等级
                  </span>
                  <simple-select
                    :options="severityOptions"
                    @input="v => (currentSeverity = v.value)"
                  ></simple-select>
                </div>
                <alert-timeline
                  :severity="currentSeverity"
                  :sourceId="hypervisorId"
                  source-type="hypervisor"
                  style="padding: 10px 4px 0 10px; overflow: auto; height: 285px"
                ></alert-timeline>
              </div>
            </a-col>
          </a-row>

          <!-- 宿主机概况 -->
          <div
            v-if="detail.hypervisor_type !== 'esxi'"
            class="module-container"
            style="min-height: 381px; margin-bottom: 16px;"
          >
            <div class="module-header" style="margin-bottom: 24px">宿主机概况</div>
            <a-row :gutter="16">
              <a-col :md="12" :xxl="6" style="margin-bottom: 16px">
                <header>电源状态统计</header>
                <div style="border-right: 1px solid #DBDBDB; margin: 26px 0 0 0; padding-right: 20px;">
                  <pie-chart
                    chart-id="host-power-state-count-chart"
                    :chart-data="count.host"
                    :flip-page="false"
                  ></pie-chart>
                </div>
              </a-col>
              <a-col :md="12" :xxl="6" style="margin-bottom: 16px">
                <header>CPU 使用率 TOP5</header>
                <host-cpu-usage-top :hypervisor-id="hypervisorId"></host-cpu-usage-top>
              </a-col>
              <a-col :md="12" :xxl="6" style="margin-bottom: 16px">
                <header>内存使用率 TOP5</header>
                <host-memory-usage-top :hypervisor-id="hypervisorId"></host-memory-usage-top>
              </a-col>
              <a-col :md="12" :xxl="6" style="margin-bottom: 16px">
                <header>存储使用率 TOP5</header>
                <storage-usage-top :hypervisor-id="hypervisorId" style="margin-top: 16px"></storage-usage-top>
              </a-col>
            </a-row>
          </div>

          <!-- 虚拟机概况 -->
          <div class="module-container" style="min-height: 381px">
            <div class="module-header" style="margin-bottom: 24px">虚拟主机概况</div>
            <a-row :gutter="16">
              <a-col :md="12" :xxl="6" style="margin-bottom: 16px">
                <header>电源状态统计</header>
                <div style="border-right: 1px solid #DBDBDB; margin: 26px 0 0 0; padding-right: 20px;">
                  <pie-chart
                    chart-id="virtual-machine-power-state-count-chart"
                    :chart-data="count.virtualMachine"
                    :flip-page="false"
                  ></pie-chart>
                </div>
              </a-col>
              <a-col :md="12" :xxl="6" style="margin-bottom: 16px">
                <header>CPU 使用率 TOP5</header>
                <virtual-machine-cpu-usage-top :hypervisor-id="hypervisorId"></virtual-machine-cpu-usage-top>
              </a-col>
              <a-col :md="12" :xxl="6" style="margin-bottom: 16px">
                <header>内存使用率 TOP5</header>
                <virtual-machine-memory-usage-top :hypervisor-id="hypervisorId"></virtual-machine-memory-usage-top>
              </a-col>
              <a-col :md="12" :xxl="6" style="margin-bottom: 16px">
                <header>磁盘占用 TOP5</header>
                <virtual-machine-disk-usage-top :hypervisor-id="hypervisorId"></virtual-machine-disk-usage-top>
              </a-col>
            </a-row>
          </div>
        </a-tab-pane>

        <a-tab-pane key="host" tab="宿主机">
          <host-list-table :hypervisor-id="hypervisorId" class="scroll-hidden-table"></host-list-table>
        </a-tab-pane>

        <a-tab-pane key="datastore" tab="数据存储">
          <datastore-list-table :hypervisor-id="hypervisorId"></datastore-list-table>
        </a-tab-pane>

        <a-tab-pane key="virtual_machine" tab="虚拟主机">
          <virtual-machine-list-table :hypervisor-id="hypervisorId" class="scroll-hidden-table"></virtual-machine-list-table>
        </a-tab-pane>
  <!--      <a-tab-pane key="network_interface" tab="网卡">-->
  <!--        <interface-list-table></interface-list-table>-->
  <!--      </a-tab-pane>-->
      </a-tabs>
      <div v-else style="height: 400px;"></div>
    </a-spin>
  </div>
</template>

<script>
import {
  getHypervisor,
  getHypervisorCount,
  getHypervisorHostCount,
  getHypervisorStatistic,
  getHypervisorVirtualMachineCount
} from '@/api/hypervisor'
import PieChart from '@/components/chart/PieChart.vue'
import HypervisorUnitIcon from '@/components/icon/HypervisorUnitIcon'
import SimpleSelect from '@/components/select/SimpleSelect'
import StorageUsageTop from '@/components/top/StorageUsageTop'
import { alertSeverities } from '@/utils/const'
import DatastoreListTable from './modules/DatastoreListTable'
import HostListTable from './modules/HostListTable'
// import InterfaceListTable from './modules/InterfaceListTable'
import VirtualMachineListTable from './modules/VirtualMachineListTable'
import CountCard from './modules/overview/CountCard'
import HostCpuUsageTop from './modules/overview/HostCpuUsageTop'
import HostMemoryUsageTop from './modules/overview/HostMemoryUsageTop'
import VirtualMachineCpuUsageTop from './modules/overview/VirtualMachineCpuUsageTop'
import VirtualMachineMemoryUsageTop from './modules/overview/VirtualMachineMemoryUsageTop'
import VirtualMachineDiskUsageTop from './modules/overview/VirtualMachineDiskUsageTop.vue'

export default {
  name: 'HypervisorDashboardDetail',
  components: {
    AlertTimeline: () => import('@/components/timeline/AlertTimeline'),
    CountCard,
    DatastoreListTable,
    HostCpuUsageTop,
    HostListTable,
    HostMemoryUsageTop,
    HypervisorUnitIcon,
    // InterfaceListTable,
    PieChart,
    SimpleSelect,
    StorageUsageTop,
    VirtualMachineCpuUsageTop,
    VirtualMachineListTable,
    VirtualMachineMemoryUsageTop,
    VirtualMachineDiskUsageTop
  },
  data () {
    return {
      spinning: false,
      activeTab: 'overview',
      currentSeverity: 'all',
      overviewCountList: [],
      hypervisorId: this.$route.params.id,
      detail: {
        id: '',
        name: '',
        cpu_core: '',
        cpu_thread: '',
        memory: '',
        version: '',
        monitor_address: '',
        hypervisor_type: 'unknown',
        created_at: '',
        updated_at: '',
        groups: [],
        status: 'unknown'
      },
      statistic: {
        cpu: {
          usage: '',
          used: '',
          total: ''
        },
        memory: {
          usage: '',
          used: '',
          total: ''
        },
        storage: {
          usage: '',
          used: '',
          total: ''
        }
      },
      count: {
        host: [],
        virtualMachine: []
      },
      severityOptions: [
        { label: '全部', value: 'all' },
        ...alertSeverities.map(item => {
          return { label: this.$t(`alert_severity.${item}`), value: item }
        })
      ],
      hostTops: {
        cpu_use_percent: 0,
        datastore_used_percent: 0,
        memory_used_percent: 0
      }
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      this.spinning = true
      const p1 = getHypervisor(this.hypervisorId).then(res => {
        this.detail = res.data
        if (this.detail.hypervisor_type !== 'esxi') {
          getHypervisorHostCount(this.hypervisorId, { count_of: 'power_state' }).then(res => {
            res.data.data.forEach(item => {
              this.count.host.push({ name: this.$t(`hypervisor_power_state.${item.name}`), value: item.value })
            })
          })
        }
      })
      const p2 = getHypervisorCount(this.hypervisorId).then(res => {
        this.overviewCountList = res.data.data
      })
      getHypervisorStatistic(this.hypervisorId).then(res => {
        this.statistic = res.data.data
      })
      getHypervisorVirtualMachineCount(this.hypervisorId, { count_of: 'power_state' }).then(res => {
        res.data.data.forEach(item => {
          this.count.virtualMachine.push({ name: this.$t(`hypervisor_power_state.${item.name}`), value: item.value })
        })
      })
      Promise.all([p1, p2]).finally(() => { this.spinning = false })
    }
  },
  filters: {
    formatData (v) {
      if (v === 0) return 0
      if (v) return v
      return '-'
    }
  }
}
</script>

<style lang="less">
.hypervisor-dashboard-detail {
  .ant-tabs-card-bar {
    margin: 0;
  }

  .module-container {
    position: relative;
    background: #fff;
    border-radius: 6px;
    padding: 16px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 8px;

    header {
      font-size: 16px;
      font-weight: 500;
      color: #096dd9;
      line-height: 22px;
    }
  }

  .base-info {
    p {
      margin: 0;
    }
    .base-container {
      height: 80px;
      position: relative;
      margin-bottom: 16px;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: space-evenly;
      border-radius: 11px;
      overflow: hidden;
    }
    .cpu-container {
      color: #096DD9;
      background: rgba(9,109,217,0.08);
    }
    .memory-container {
      color: #47D7B6;
      background: rgba(71,215,182,0.08);
    }
    .storage-container {
      color: #F7B500;
      background: rgba(247,181,0,0.08);
    }
    .base-container .overview-base-card-container {
      width: 128px;
    }
    .title {
      font-size: 14px;
      color: rgba(0,0,0,0.85);
      line-height: 20px;
    }
    .value {
      font-size: 18px;
      font-weight: 500;
      line-height: 25px;
      margin-bottom: 4px;
    }
    .card-title {
      font-weight: 500;
      font-size: 14px;
      margin-top: 4px;
    }
  }
}
</style>
