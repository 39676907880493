<template>
  <a-dropdown
    :trigger="['click']"
    overlay-class-name="setting-button"
    style="cursor: pointer"
  >
    <a class="button">
      <a-icon
        type="setting"
        style="font-size: 13px; vertical-align: text-bottom; padding-bottom: 1px;"
      ></a-icon>
      <span style="font-size: 12px; margin-left: 4px;">设置</span>
    </a>

    <a-menu slot="overlay">
      <a-checkbox
        :indeterminate="indeterminate"
        :checked="checked"
        @change="changeAll"
        style="margin: 4px 8px"
      >
        全选
      </a-checkbox>
      <a-button
        size="small"
        type="link"
        @click="change(defaultSelected)"
      >
        默认</a-button>
      <a-menu-divider></a-menu-divider>
      <a-checkbox-group
        v-model="checkedList"
        :options="options"
        @change="change"
      ></a-checkbox-group>
    </a-menu>
  </a-dropdown>
</template>

<script>
export default {
  name: 'SettingButton',
  props: {
    defaultSelected: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      indeterminate: false,
      checked: false,
      checkedList: this.defaultSelected,
      tableColumns: []
    }
  },
  computed: {
    options () {
      return this.columns.map(column => {
        return {
          // 针对自定义表头
          label: column.title || column.slots?.name,
          value: column.dataIndex
        }
      })
    }
  },
  methods: {
    changeAll (e) {
      Object.assign(this, {
        checkedList: e.target.checked
          ? this.options.map(option => option.value)
          : [],
        indeterminate: false,
        checked: e.target.checked
      })
      this.$emit('selected', this.checked ? this.columns : [])
    },
    change (checkedList) {
      Object.assign(this, {
        indeterminate:
          !!checkedList.length && checkedList.length < this.options.length,
        checked: checkedList.length === this.options.length,
        checkedList: checkedList
      })
      this.$emit(
        'selected',
        this.columns.filter(
          column => checkedList.indexOf(column.dataIndex) !== -1
        )
      )
    }
  }
}
</script>

<style lang="less">
.setting-button {
  .ant-checkbox-group-item {
    display: block;
    margin: 4px 8px;
  }
}

a.button {
  color: #575757;
  user-select: none;
}
a.button:hover {
  color: #0880ff;
}
</style>
