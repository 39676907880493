<template>
  <a-progress
    :percent="percent"
    size="small"
    status="active"
    :stroke-color="{
      from: '#096dd9',
      to: color
    }"
    :strokeWidth="12"
  >
    <span
      slot="format"
      slot-scope="percent"
      :style="{
        color: color,
        userSelect: 'none'
      }"
    >
      {{ `${percent}%` }}
    </span>
  </a-progress>
</template>

<script>
export default {
  name: 'UsageProgress',
  props: {
    percent: {
      type: Number,
      default: 0.0
    }
  },
  computed: {
    color () {
      if (this.percent > 85) {
        return '#e97659'
      } else if (this.percent > 75) {
        return '#ffa040'
      } else {
        return '#096dd9'
      }
    }
  }
}
</script>
