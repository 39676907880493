<template>
    <a-row :gutter="12"  id="circle-progress-top-container">
      <template v-if="topData.length">
        <a-col :span="8" v-for="(item,index) in topData" :key="item.name">
          <div class="body-container">
            <a-progress type="circle" :percent="item.value" :width="80" :stroke-color="getColor(item.value)">
              <template #format="percent">
                <div
                  :style="{
                    color: getColor(item.value),
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: '20px',
                    marginTop: '4px'
                  }"
                >
                  {{ percent }}%
                </div>
                <div style="font-size: 12px; font-weight: 500; line-height: 17px; color: rgba(0,0,0,0.85);">TOP {{index+1}}</div>
              </template>
            </a-progress>
            <slot name="progressTitle">
              <a-tooltip :title="item.name">
                <p class="top-name">{{item.name}}</p>
              </a-tooltip>
            </slot>
          </div>
        </a-col>
      </template>
      <a-col v-else style="padding-top: 48px;">
        <a-empty></a-empty>
      </a-col>
    </a-row>
</template>

<script>
export default {
  name: 'CircleProgressTop',
  props: {
    topData: {
      type: Array,
      required: true
    }
  },
  methods: {
    getColor (value) {
      if (value >= 90) return '#FF5B73'
      return '#096DD9'
    }
  }
}
</script>

<style lang="less">
#circle-progress-top-container{
  overflow: hidden;
  .body-container {
    width: 80px;
    text-align: center;
    margin: 8px auto 16px;
    .top-name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 88px;
      font-size: 12px;
      color: rgba(0,0,0,0.85);
      line-height: 17px;
      margin: 4px 0 0 -4px;
    }
  }
}
</style>
