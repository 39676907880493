<template>
  <div id="cpu-usage-top">
    <template v-if="topData.length">
      <div
        class="usage-top-container"
        v-for="(item, index) in topData"
        :key="item.name"
      >
        <div
          style="
            height: 22px;
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div class="name-container">
            <div
              class="name-flag"
              :style="{
                borderColor: getColor(item.value),
                color: getColor(item.value)
              }"
            >
              TOP{{ index + 1 }}
            </div>
            <div class="name-text">{{ item.name }}</div>
            <div class="ip-text" v-if="item.monitor_address && showIP">
              IP 地址：{{ item.monitor_address }}
            </div>
          </div>

          <div class="value-container">
            {{ item.value }} <span style="font-size: 12px">%</span>
          </div>
        </div>
        <div class="progress-container">
          <a-progress
            :stroke-width="strokeWidth"
            stroke-linecap="square"
            :percent="item.value"
            :show-info="false"
            size="small"
            status="active"
            :stroke-color="getColor(item.value)"
          ></a-progress>
        </div>
      </div>
    </template>
    <div v-else style="padding-top: 48px">
      <a-empty></a-empty>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CpuUsageTop',
  props: {
    topData: {
      type: Array,
      required: true
    },
    strokeWidth: {
      type: Number,
      default: 6
    },
    showIP: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getColor (value) {
      if (value >= 90) return '#FF5B73'
      return '#096DD9'
    }
  }
}
</script>

<style lang="less">
#cpu-usage-top {
  .usage-top-container {
    height: 32px;
    overflow: hidden;
    margin-bottom: 16px;
    .name-container {
      font-size: 12px;
      line-height: 17px;
      display: flex;
      align-items: center;
      width: calc(100% - 56px);
      .name-flag {
        background-color: #fff;
        width: 40px;
        height: 22px;
        border: 1px solid;
        font-weight: 500;
        text-align: center;
        line-height: 22px;
        margin-right: 4px;
      }
      .name-text {
        color: rgba(0, 0, 0, 0.85);
        line-height: 32px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .ip-text {
        margin: 4px 8px 0 6px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: rgba(0, 0, 0, 0.45);
        font-size: 11px;
      }
    }

    .value-container {
      font-size: 16px;
      color: #096dd9;
      font-weight: 500;
      line-height: 22px;
      width: 64px;
      white-space: nowrap;
      text-align: right;
    }
    .progress-container {
      margin-top: -7px;
      .ant-progress-inner {
        border-radius: 0;
      }
    }
  }
}
</style>
