<template>
  <div :id="chartId"></div>
</template>

<script>
// 雷达图 面积图
import { Chart } from '@antv/g2'
import { subTitle } from '@/utils'

const chart = {}

export default {
  name: 'RadarChart',
  props: {
    chartData: {
      type: Array,
      default: () => []
    },
    chartId: {
      type: String,
      required: true
    },
    height: {
      type: Number,
      default: 240
    },
    legendPosition: {
      type: String,
      default: 'bottom'
    },
    flipPage: {
      type: Boolean,
      default: true
    }
  },
  mounted () {
    chart[this.chartId] = new Chart({
      container: this.chartId,
      autoFit: true,
      height: this.height,
      appendPadding: [0, 20, 0, 20]
    })
    chart[this.chartId].scale('value', {
      min: 0,
      max: 100,
      alias: '使用率',
      formatter (val) {
        return `${val} %`
      }
    })

    chart[this.chartId].coordinate('polar', {
      radius: 0.8
    })
    chart[this.chartId].axis('name', {
      line: null,
      tickLine: null,
      label: {
        autoEllipsis: true,
        style: {
          fill: '#8E8E8E',
          fontSize: 12
        },
        formatter: v => {
          return subTitle(v, 8)
        }
      },
      verticalLimitLength: 0.1,
      grid: {
        line: {
          style: {
            lineDash: null
          }
        }
      }
    })

    chart[this.chartId].axis('value', {
      line: null,
      tickLine: null,
      label: null,
      grid: {
        line: {
          type: 'line',
          style: {
            lineDash: null,
            stroke: '#E3E3E3'
          }
        }
      }
    })
    chart[this.chartId].tooltip({
      showCrosshairs: true,
      crosshairs: {
        line: {
          style: {
            lineDash: [4, 4],
            stroke: '#333'
          }
        }
      }
    })
    chart[this.chartId].line().position('name*value').color('#FAAD14').size(2)
    chart[this.chartId].area().position('name*value').color('#FAAD14')
    chart[this.chartId]
      .point()
      .position('name*value')
      .shape('circle')
      .color('#FAAD14')
      .size(3)
      .style({
        stroke: '#FAAD14',
        lineWidth: 2,
        fill: '#fff',
        fillOpacity: 1
      })

    this.render()
  },
  methods: {
    render () {
      if (this.chartData.length === 0) {
        chart[this.chartId].changeVisible(false)
      } else {
        chart[this.chartId].changeData(this.chartData)
        chart[this.chartId].changeVisible(true)
      }
    }
  },
  watch: {
    chartData () {
      this.render()
    }
  }
}
</script>
