<template>
  <a-select
    v-model="selected"
    :label-in-value="true"
    :options="options"
    @change="change"
    :disabled="disabled"
    :style="{width: width + 'px', height: height + 'px'}"
  ></a-select>
</template>

<script>
export default {
  name: 'SimpleSelect',
  props: {
    options: {
      type: Array,
      default: () => []
    },
    value: {
      type: Object
    },
    width: {
      type: Number,
      default: 114
    },
    height: {
      type: Number,
      default: 32
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selected: undefined
    }
  },
  mounted () {
    if (this.value) this.selected = this.value
    else {
      this.selected = this.options[0]
    }
  },
  methods: {
    change (value) {
      this.$emit('input', { label: value.label, value: value.key })
    }
  },
  watch: {
    value: {
      handler (v, ov) {
        this.selected = v
      },
      deep: true
    }
  }
}
</script>
