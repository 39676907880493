var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-progress',{attrs:{"percent":_vm.percent,"size":"small","status":"active","stroke-color":{
    from: '#096dd9',
    to: _vm.color
  },"strokeWidth":12},scopedSlots:_vm._u([{key:"format",fn:function(percent){return _c('span',{style:({
      color: _vm.color,
      userSelect: 'none'
    })},[_vm._v(" "+_vm._s(`${percent}%`)+" ")])}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }