<template>
  <div style="border-right: 1px solid #DBDBDB; margin: 26px 0 0 0; padding-right: 8px; height: 240px;">
    <a-spin :spinning="spinning">
      <cpu-usage-top v-if="topData.length" :top-data="topData"></cpu-usage-top>
      <div v-else style="height: 240px; position: relative; overflow: hidden;">
        <empty-component
          :imgUrl="require('@/assets/images/info_empty.png')"
          :body-style="{
            height: '120px',
            marginTop: '28px'
          }"
          :description-style="{
            marginTop: '-8px',
            marginRight: '8px'
          }"
          description="暂无数据"
        ></empty-component>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { getHypervisorVirtualMachineTop } from '@/api/hypervisor'
import CpuUsageTop from '@/components/top/CpuUsageTop'

export default {
  name: 'VirtualMachineCpuUsageTop',
  components: {
    EmptyComponent: () => import('@/components/EmptyComponent.vue'),
    CpuUsageTop
  },
  props: {
    hypervisorId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      topData: [],
      spinning: false
    }
  },
  mounted () {
    this.spinning = true
    getHypervisorVirtualMachineTop(this.hypervisorId, { top_of: 'cpu', limit: 5 }).then(res => {
      this.topData = res.data.data
    }).finally(() => { this.spinning = false })
  }
}
</script>
