var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"host-list-table-container"}},[_c('a-alert',{staticStyle:{"margin-bottom":"16px"},attrs:{"message":"宿主机会根据 IP 地址自动关联物理机。","show-icon":true}}),_c('div',{staticStyle:{"height":"32px","margin-bottom":"16px","font-size":"14px","line-height":"32px"}},[_c('a-space',{staticStyle:{"float":"right"},attrs:{"size":"middle"}},[_c('a-input-search',{attrs:{"placeholder":"请输入关键词"},on:{"search":_vm.search}}),_c('reload-button',{on:{"click":_vm.fetch}}),_c('setting-button',{attrs:{"columns":_vm.columnsAll,"default-selected":_vm.columnDefaultSelected},on:{"selected":_vm.updateColumns}})],1)],1),_c('div',{staticClass:"custom-table"},[_c('a-table',{staticStyle:{"margin-bottom":"16px"},attrs:{"columns":_vm.columns,"data-source":_vm.dataSource,"loading":_vm.loading,"pagination":false,"row-key":"id","scroll":{ x: _vm.scrollX }},scopedSlots:_vm._u([{key:"name",fn:function(text){return [_c('a-tooltip',{attrs:{"placement":"topLeft","title":text}},[_vm._v(_vm._s(text))])]}},{key:"memory",fn:function(text){return [_vm._v(" "+_vm._s(text ? `${text} GB` : '-')+" ")]}},{key:"server",fn:function(text){return [(text)?_c('a-tooltip',{attrs:{"title":text.name}},[_c('a',{on:{"click":function($event){return _vm.$refs.serverDrawer.show(text.id)}}},[_vm._v(_vm._s(text.name))])]):_c('span',[_vm._v("-")])]}},{key:"operation",fn:function(text, record){return [_c('a-button',{attrs:{"icon":"link","size":"small","type":"link"},on:{"click":function($event){return _vm.$refs.modal.show(record)}}},[_vm._v(" 关联 ")])]}}])}),_c('div',{staticStyle:{"height":"32px"}},[_c('a-pagination',{staticStyle:{"float":"right"},attrs:{"pageSize":_vm.form.pageSize,"show-size-changer":true,"show-total":t => `共 ${t} 条`,"total":_vm.total},on:{"change":page => {
          this.form.page = page
          this.fetch()
        },"showSizeChange":(_, pageSize) => {
          this.form.page = 1
          this.form.pageSize = pageSize
          this.fetch()
        }},model:{value:(_vm.form.page),callback:function ($$v) {_vm.$set(_vm.form, "page", $$v)},expression:"form.page"}})],1),_c('server-drawer',{ref:"serverDrawer"}),_c('host-link-modal',{ref:"modal",attrs:{"hypervisor-id":_vm.hypervisorId},on:{"ok":_vm.fetch}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }