<template>
  <div style="display: flex; overflow: hidden; padding-right: 6px">
    <div :id="chartId" style="width: 60%;"></div>
    <div id="radial-bar-chart-legend"></div>
  </div>
</template>

<script>
// 玉珏图 自定义图例
import { Chart } from '@antv/g2'

const chart = {}

export default {
  name: 'RadialBarChart',
  props: {
    chartData: {
      type: Array,
      default: () => []
    },
    chartId: {
      type: String,
      required: true
    },
    height: {
      type: Number,
      default: 240
    },
    legendPosition: {
      type: String,
      default: 'bottom'
    },
    legendPositionCenter: {
      type: Boolean,
      default: false
    },
    flipPage: {
      type: Boolean,
      default: true
    },
    maxValue: {
      type: Number,
      default: 100
    },
    unit: {
      type: String,
      default: '%'
    },
    annotationHtml: {
      type: String
    },
    colors: {
      type: [Array, String],
      default: () => ['#348BFF', '#3AD3F9', '#F1993D', '#5366F6', '#A895EF']
    },
    barSize: {
      type: Number,
      default: 8
    }
  },
  mounted () {
    chart[this.chartId] = new Chart({
      container: this.chartId,
      autoFit: true,
      height: this.height,
      appendPadding: [0, 0, 20, 0]
    })
    chart[this.chartId].axis(false)
    if (this.maxValue) {
      chart[this.chartId].scale('value', {
        min: 0,
        max: this.maxValue
      })
    }
    chart[this.chartId].tooltip({
      showMarkers: false
    })
    chart[this.chartId].legend(false)
    chart[this.chartId].coordinate('theta', { innerRadius: 0.25, radius: 0.9 })
    if (this.annotationHtml) {
      chart[this.chartId].annotation().html({
        position: ['50%', '50%'],
        html: () => {
          return this.annotationHtml
        }
      })
    }
    chart[this.chartId]
      .interval({
        background: { style: { fill: '#F2F2F2', fillOpacity: 1 } }
      })
      .size(this.barSize)
      .position('name*value')
      .color('name', this.colors)
      .style({
        lineCap: 'round'
      })
    const $legend = document.getElementById('radial-bar-chart-legend')
    chart[this.chartId].on('afterchangedata', e => {
      const elements = e.view.getElements()
      const mappingData = elements.map(e => e.getModel())
      $legend.innerHTML = `
            ${mappingData
              .map(datum => {
                const color = datum.color
                const name = datum.data.name
                const value = `${datum.data.value}${this.unit}`
                return `
                <div class="${this.legendPositionCenter ? 'legend-item-center legend-item' : 'legend-item'}">
                  <div style="display: flex; align-items: center">
                    <div class="legend-item-marker" style="background: ${color}"></div>
                    <div class="legend-item-name">${name}</div>
                    <span class="legend-item-value">${value}</span>
                  </div>
                </div>`
              })
              .join('')}
          `
    })
    this.render()
  },
  methods: {
    render () {
      if (this.chartData.length === 0) {
        chart[this.chartId].changeVisible(false)
      } else {
        chart[this.chartId].changeData(this.chartData)
        chart[this.chartId].changeVisible(true)
      }
    }
  },
  watch: {
    chartData: {
      handler (v) {
        if (v) this.render()
      },
      deep: true
    }
  }
}
</script>

<style lang="less">
#radial-bar-chart-legend {
  margin-left: 8px;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  .legend-item {
    line-height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    overflow: hidden;

    .legend-item-marker {
      width: 8px;
      height: 8px;
      min-width: 8px;
      border-radius: 50%;
      margin-right: 6px;
    }
    .legend-item-name {
      font-size: 12px;
      color: #8E8E8E;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
      margin-right: 4px;
    }
    .legend-item-value {
      overflow: hidden;
      font-size: 12px;
      color: #096DD9;
      font-weight: 500;
    }
  }

  .legend-item-center {
    justify-content: center;
  }
}
</style>
