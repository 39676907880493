<template>
  <div style="border-right: 1px solid #DBDBDB; margin: 26px 0 0 0; padding-right: 8px;">
    <a-spin :spinning="spinning">
      <radar-chart
        v-if="chartData.length"
        chart-id="overview-cpu-usage-chart"
        :chart-data="chartData"
        legendPosition="right"
        :flip-page=false
      ></radar-chart>
      <div v-else style="height: 240px; position: relative; overflow: hidden;">
        <empty-component
          :imgUrl="require('@/assets/images/info_empty.png')"
          :body-style="{
            height: '120px',
            marginTop: '28px'
          }"
          :description-style="{
            marginTop: '-8px',
            marginRight: '8px'
          }"
          description="暂无数据"
        ></empty-component>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { getHypervisorHostTop } from '@/api/hypervisor'
import RadarChart from '@/components/chart/RadarChart'

export default {
  name: 'HostCpuUsageTop',
  components: {
    EmptyComponent: () => import('@/components/EmptyComponent.vue'),
    RadarChart
  },
  props: {
    hypervisorId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      chartData: [],
      spinning: false
    }
  },
  mounted () {
    this.spinning = true
    getHypervisorHostTop(this.hypervisorId, { top_of: 'cpu', limit: 5 }).then(res => {
      this.chartData = res.data.data
    }).finally(() => { this.spinning = false })
  }
}
</script>
