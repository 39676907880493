var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin":"26px 0 0 0","height":"240px"}},[_c('a-spin',{attrs:{"spinning":_vm.spinning}},[(_vm.chartData.length)?_c('bar-chart',{attrs:{"chart-data":_vm.chartData,"chart-id":"virtual-machine-storage-usage-chart"}}):_c('div',{staticStyle:{"height":"240px","position":"relative","overflow":"hidden"}},[_c('empty-component',{attrs:{"imgUrl":require('@/assets/images/info_empty.png'),"body-style":{
          height: '120px',
          marginTop: '28px'
        },"description-style":{
          marginTop: '-8px',
          marginRight: '8px'
        },"description":"暂无数据"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }