<template>
  <div id="host-list-table-container">
    <a-alert message="宿主机会根据 IP 地址自动关联物理机。" :show-icon="true" style="margin-bottom: 16px"></a-alert>

    <div
      style="
        height: 32px;
        margin-bottom: 16px;
        font-size: 14px;
        line-height: 32px;
      "
    >
      <a-space size="middle" style="float: right">
        <a-input-search
          placeholder="请输入关键词"
          @search="search"
        ></a-input-search>
        <reload-button @click="fetch"></reload-button>

        <setting-button
          :columns="columnsAll"
          :default-selected="columnDefaultSelected"
          @selected="updateColumns"
        ></setting-button>
      </a-space>
    </div>

    <div class="custom-table">
      <a-table
        :columns="columns"
        :data-source="dataSource"
        :loading="loading"
        :pagination="false"
        row-key="id"
        :scroll="{ x: scrollX }"
        style="margin-bottom: 16px"
      >
        <template slot="name" slot-scope="text">
          <a-tooltip placement="topLeft" :title="text">{{ text }}</a-tooltip>
        </template>
        <template slot="memory" slot-scope="text">
          {{ text ? `${text} GB` : '-' }}
        </template>
        <template slot="server" slot-scope="text">
          <a-tooltip v-if="text" :title="text.name">
            <a @click="$refs.serverDrawer.show(text.id)">{{ text.name }}</a>
          </a-tooltip>
          <span v-else>-</span>
        </template>
        <template slot="operation" slot-scope="text, record">
          <a-button
            icon="link"
            size="small"
            type="link"
            @click="$refs.modal.show(record)"
          >
            关联
          </a-button>
        </template>
      </a-table>

      <div style="height: 32px">
        <a-pagination
          v-model="form.page"
          :pageSize="form.pageSize"
          :show-size-changer="true"
          :show-total="t => `共 ${t} 条`"
          :total="total"
          @change="
          page => {
            this.form.page = page
            this.fetch()
          }
        "
          @showSizeChange="
          (_, pageSize) => {
            this.form.page = 1
            this.form.pageSize = pageSize
            this.fetch()
          }
        "
          style="float: right"
        ></a-pagination>
      </div>

      <server-drawer ref="serverDrawer"></server-drawer>
      <host-link-modal ref="modal" :hypervisor-id="hypervisorId" @ok="fetch"></host-link-modal>
    </div>
  </div>
</template>

<script>
import { getHypervisorHostList } from '@/api/hypervisor'
import ReloadButton from '@/components/button/ReloadButton'
import SettingButton from '@/components/button/SettingButton'
import HostLinkModal from '@/components/table/VirtualMachineTable/modules/HostLinkModal'

export default {
  name: 'HostListTable',
  components: {
    // ExportButton,
    ReloadButton,
    SettingButton,
    HostLinkModal,
    'server-drawer': () => import('@/components/drawer/ServerDrawer')
  },
  props: {
    hypervisorId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      form: {
        name: '',
        page: 1,
        pageSize: 10
      },
      columnsAll: [
        {
          dataIndex: 'name',
          title: '名称',
          fixed: 'left',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'ip_address',
          title: 'IP 地址',
          width: 160
        },
        {
          dataIndex: 'version',
          title: '版本',
          width: 160,
          ellipsis: true
        },
        {
          dataIndex: 'cpu_core',
          title: 'CPU 核数',
          align: 'center',
          width: 100
        },
        {
          dataIndex: 'cpu_thread',
          title: 'CPU 线程数',
          align: 'center',
          width: 100
        },
        {
          dataIndex: 'memory',
          title: '内存',
          align: 'center',
          width: 100,
          scopedSlots: {
            customRender: 'memory'
          }
        },
        {
          dataIndex: 'server',
          title: '关联物理机',
          width: 200,
          scopedSlots: {
            customRender: 'server'
          }
        },
        {
          dataIndex: 'created_at',
          title: '创建时间',
          width: 200
        },
        {
          dataIndex: 'updated_at',
          title: '更新时间',
          width: 200
        },
        {
          dataIndex: 'operation',
          title: '操作',
          align: 'center',
          fixed: 'right',
          width: 90,
          scopedSlots: {
            customRender: 'operation'
          }
        }
      ],
      columns: [],
      columnDefaultSelected: [
        'name',
        'ip_address',
        'version',
        'cpu_core',
        'cpu_thread',
        'memory',
        'updated_at',
        'server',
        'operation'
      ],
      dataSource: [],
      total: 0,
      loading: false
    }
  },
  computed: {
    scrollX () {
      let sum = 0
      this.columns.forEach(column => {
        sum += column.width
      })
      return sum
    }
  },
  mounted () {
    this.columns = this.columnsAll.filter(column => this.columnDefaultSelected.indexOf(column.dataIndex) !== -1)
    this.fetch()
  },
  methods: {
    fetch () {
      const params = {
        page: this.form.page,
        page_size: this.form.pageSize
      }
      if (this.form.name) params.name = this.form.name
      this.loading = true
      getHypervisorHostList(this.hypervisorId, params)
        .then(res => {
          const data = res.data
          this.dataSource = data.data
          this.total = data.total * 1
        })
        .finally(() => {
          this.loading = false
        })
    },
    search (value) {
      this.form.name = value
      this.form.page = 1
      this.fetch()
    },
    updateColumns (columns) {
      this.columns = [...columns]
    }
  }
}
</script>

<style lang="less">
#host-list-table-container {
  background: #fff;
  border-radius: 6px;
  padding: 24px 32px 20px 40px;

  .list-table-search {
    width: 200px;
    .ant-input {
      background-color: #fff;
    }
  }
}
</style>
