<template>
  <div id="usage-info">
    <a-spin :spinning="spinning">
      <template v-if="topData.length">
        <div
          class="usage-info-item"
          v-for="(item, index) in topData"
          :key="item.name"
        >
          <div style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis; width: 180px;">
            <img
              v-if="index < 3"
              :src="require(`@/assets/icons/fire/fire${index + 1}.svg`)"
              style="margin: 0 17px 0 0; width: 19px; height: 19px"
            />
            <span
              v-else
              style="
                font-size: 14px;
                font-weight: 400;
                color: #686e7e;
                line-height: 20px;
                display: inline-block;
                text-align: center;
                margin-right: 19px;
                width: 19px;
              "
              >
              0{{ index + 1 }}
            </span>
            <a-tooltip :title="item.name">{{ item.name }}</a-tooltip>
          </div>
          <div class="item-progress">
            <a-progress
              :percent="item.value"
              :show-info="false"
              size="small"
              :stroke-color="getColor(item.value)"
              :stroke-width="8"
            ></a-progress>
          </div>
          <div class="item-value">{{ item.value }} %</div>
        </div>
      </template>
      <div v-else style="height: 240px; position: relative; overflow: hidden;">
        <empty-component
          :imgUrl="require('@/assets/images/info_empty.png')"
          :body-style="{
            height: '120px',
            marginTop: '40px'
          }"
          :description-style="{
            marginTop: '-8px',
            marginRight: '8px'
          }"
          description="暂无数据"
        ></empty-component>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { getHypervisorHostTop } from '@/api/hypervisor'

export default {
  name: 'StorageUsageTop',
  components: {
    EmptyComponent: () => import('@/components/EmptyComponent.vue')
  },
  props: {
    hypervisorId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      topData: [],
      spinning: false
    }
  },
  mounted () {
    this.spinning = true
    getHypervisorHostTop(this.hypervisorId, { top_of: 'storage', limit: 5 }).then(res => {
      this.topData = res.data.data
    }).finally(() => { this.spinning = false })
  },
  methods: {
    getColor (percent) {
      if (percent >= 90) return '#FF5B73'
      return '#096DD9'
    }
  }
}
</script>

<style lang="less">
#usage-info {
  .usage-info-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 34px;
    background: #f7f8f9;
    border-radius: 4px;
    margin-bottom: 14px;
    font-size: 12px;
    font-weight: 500;
    color: #3a3f46;
    line-height: 20px;
    padding: 8px 10px 8px 14px;
    &:last-of-type {
      margin: 0;
    }

    .usage-count {
      font-size: 12px;
      font-weight: 500;
      color: #3A3F46;
    }

    .item-progress {
      min-width: 60px;
      margin: 0 24px;
      flex: 1;

      .ant-progress-inner {
        background: #D8D8D8;
      }
    }

    .item-value {
      width: 50px;
      font-size: 14px;
      color: #096dd9;
      line-height: 25px;
      text-align: right;
      white-space: nowrap;
    }
  }
}
</style>
