<template>
  <div :id="chartId"></div>
</template>

<script>
import { Chart } from '@antv/g2'
import { chartColors } from '@/utils/const'

const chart = {}
const view = {}

export default {
  name: 'PieChart',
  props: {
    chartId: {
      type: String,
      required: true
    },
    chartData: {
      type: Array,
      default: () => []
    },
    colors: {
      type: [Array, Function],
      default: () => chartColors
    },
    height: {
      type: Number,
      default: 240
    },
    legendPosition: {
      type: String,
      default: 'bottom'
    },
    colorType: {
      type: String,
      default: 'name'
    },
    flipPage: {
      type: Boolean,
      default: true
    },
    size: {
      type: Number,
      default: -1
    },
    legendOffsetX: {
      type: Number,
      default: 0
    }
  },
  computed: {
    totalValue () {
      let sum = 0
      if (this.chartData.length !== 0) {
        this.chartData.forEach(item => {
          sum += item.value
        })
      }
      return sum
    }
  },
  mounted () {
    chart[this.chartId] = new Chart({
      container: this.chartId,
      autoFit: true,
      height: this.height
    })
    chart[this.chartId].coordinate('theta', {
      radius: 0.9,
      innerRadius: 0.65
    })
    chart[this.chartId].tooltip(false)
    chart[this.chartId].legend({
      flipPage: this.flipPage,
      position: this.legendPosition,
      offsetX: this.legendOffsetX,
      itemValue: {
        formatter: (text, arr, index) => {
          return this.chartData[index].value
        }
      }
    })
    if (this.size >= 0) {
      chart[this.chartId]
        .interval()
        .adjust('stack')
        .position('value')
        .size(this.size)
        .color(this.colorType, this.colors)
        .style({
          fillOpacity: 1,
          lineWidth: 5,
          stroke: '#ffffff'
        })
        .state({
          active: {
            style: element => {
              const shape = element.shape
              return {
                lineWidth: 1,
                stroke: shape.attr('fill'),
                strokeOpacity: shape.attr('fillOpacity')
              }
            }
          }
        })
    } else {
      chart[this.chartId]
        .interval()
        .adjust('stack')
        .position('value')
        .color(this.colorType, this.colors)
        .style({
          fillOpacity: 1,
          lineWidth: 5,
          stroke: '#ffffff'
        })
        .state({
          active: {
            style: element => {
              const shape = element.shape
              return {
                lineWidth: 1,
                stroke: shape.attr('fill'),
                strokeOpacity: shape.attr('fillOpacity')
              }
            }
          }
        })
    }

    chart[this.chartId].interaction('element-active')
    // 先初始化 view
    view[this.chartId] = chart[this.chartId].createView()
    chart[this.chartId].on('element:statechange', e => {
      const { state, stateStatus, element } = e.gEvent.originalEvent
      if (state === 'active') {
        const data = element.getData()
        if (stateStatus) {
          this.updateAnnotation(data.name, data.value)
        } else {
          this.updateAnnotation('总数', this.totalValue)
        }
      }
    })
    this.render()
  },
  methods: {
    updateAnnotation (name, value) {
      view[this.chartId].annotation().clear(true)
      view[this.chartId]
        .annotation()
        .text({
          position: ['50%', '48%'],
          content: name,
          style: {
            fontSize: 12,
            fill: '#8c8c8c',
            textAlign: 'center'
          },
          offsetY: -16
        })
        .text({
          position: ['50%', '56%'],
          content: value,
          style: {
            fontSize: 32,
            fill: '#8c8c8c',
            textAlign: 'center'
          }
        })
      view[this.chartId].render(true)
    },
    render () {
      chart[this.chartId].changeData(this.chartData)
      this.updateAnnotation('总数', this.totalValue)
    }
  },
  watch: {
    chartData () {
      this.render()
    }
  }
}
</script>
