import qs from 'qs'
import request from '@/utils/request'

const urlPrefix = '/hypervisors'

export function getHypervisor (id) {
  return request.get(`${urlPrefix}/${id}`)
}

export function updateHypervisor (id, data) {
  return request.put(`${urlPrefix}/${id}`, data)
}

export function deleteHypervisor (id) {
  return request.delete(`${urlPrefix}/${id}`)
}

export function getHypervisorCount (id) {
  return request.get(`${urlPrefix}/${id}/count`)
}

export function getHypervisorStatistic (id) {
  return request.get(`${urlPrefix}/${id}/statistic`)
}

export function getHypervisorMonitorStatusList (id) {
  return request.get(`${urlPrefix}/${id}/monitor/statuses`)
}

export function getHypervisorMonitorItemList (id, params) {
  return request.get(`${urlPrefix}/${id}/monitor/items`, { params })
}

export function getHypervisorMonitorHistory (id, params) {
  return request.get(`${urlPrefix}/${id}/monitor/history`, { params })
}

export function getHypervisorMonitorTriggerList (id, params) {
  return request.get(`${urlPrefix}/${id}/monitor/triggers`, { params })
}

export function updateHypervisorMonitorTrigger (id, data) {
  return request.post(`${urlPrefix}/${id}/monitor/triggers`, data)
}

export function getHypervisorMonitorMacro (id) {
  return request.get(`${urlPrefix}/${id}/monitor/macros`)
}

export function getHypervisorHostList (id, params) {
  return request.get(`${urlPrefix}/${id}/hosts`, { params })
}

export function updateHypervisorHost (id, data) {
  return request.put(`${urlPrefix}/${id}/hosts`, data)
}

export function getHypervisorHostCount (id, params) {
  return request.get(`${urlPrefix}/${id}/hosts/count`, { params })
}

export function getHypervisorHostTop (id, params) {
  return request.get(`${urlPrefix}/${id}/hosts/top`, { params })
}

export function getHypervisorDatastoreList (id, params) {
  return request.get(`${urlPrefix}/${id}/datastores`, { params })
}

export function getHypervisorVirtualMachineList (id, params) {
  return request.get(`${urlPrefix}/${id}/virtual-machines`, { params })
}

export function updateHypervisorVirtualMachine (id, data) {
  return request.put(`${urlPrefix}/${id}/virtual-machines`, data)
}

export function getHypervisorVirtualMachineCount (id, params) {
  return request.get(`${urlPrefix}/${id}/virtual-machines/count`, { params })
}

export function getHypervisorVirtualMachineTop (id, params) {
  return request.get(`${urlPrefix}/${id}/virtual-machines/top`, { params })
}

export function getHypervisorList (params) {
  return request.get(urlPrefix, { params })
}

export function createHypervisor (data) {
  return request.post(urlPrefix, data)
}

export function syncHypervisor (data) {
  return request.post(`${urlPrefix}/sync`, data)
}

export function exportHypervisor (params) {
  return request.get(`${urlPrefix}/export`, {
    params,
    paramsSerializer: obj => {
      return qs.stringify(obj, { arrayFormat: 'repeat' })
    },
    responseType: 'blob'
  })
}

export function getHypervisorGroup (id) {
  return request.get(`${urlPrefix}/groups/${id}`)
}

export function updateHypervisorGroup (id, data) {
  return request.put(`${urlPrefix}/groups/${id}`, data)
}

export function deleteHypervisorGroup (id) {
  return request.delete(`${urlPrefix}/groups/${id}`)
}

export function getHypervisorGroupList () {
  return request.get(`${urlPrefix}/groups/tree`)
}

export function createHypervisorGroup (data) {
  return request.post(`${urlPrefix}/groups`, data)
}
