<template>
  <div id="virtual-machine-list-table-container">
    <a-alert
      message="虚拟主机会根据 IP 地址自动关联操作系统。虚拟主机的 IP 地址需要安装 VMware Tools 才能自动获取。"
      :show-icon="true"
      style="margin-bottom: 16px"
    ></a-alert>

    <div
      style="
        height: 32px;
        margin-bottom: 16px;
        font-size: 14px;
        line-height: 32px;
      "
    >
      <a-space size="middle" style="float: right">
        <a-input-search
          placeholder="请输入关键词"
          @search="search"
        ></a-input-search>
        <reload-button @click="fetch"></reload-button>

        <setting-button
          :columns="columnsAll"
          :default-selected="columnDefaultSelected"
          @selected="updateColumns"
        ></setting-button>
      </a-space>
    </div>

    <div class="custom-table">
      <a-table
        :columns="columns"
        :data-source="dataSource"
        :loading="loading"
        :pagination="false"
        row-key="id"
        :scroll="{ x: scrollX }"
        style="margin-bottom: 16px"
      >
        <template slot="name" slot-scope="text">
          <a-tooltip placement="topLeft" :title="text">{{ text }}</a-tooltip>
        </template>
        <template slot="cpu" slot-scope="text">
          {{ text ? `${text} 核` : '-' }}
        </template>
        <template slot="memory" slot-scope="text">
          {{ text ? `${text} GB` : '-' }}
        </template>
        <template slot="os" slot-scope="text">
          <a-tooltip v-if="text" :title="text.name">
            <a @click="$refs.osDrawer.show(text.id)">{{ text.name }}</a>
          </a-tooltip>
          <span v-else>-</span>
        </template>
        <template slot="operation" slot-scope="text, record">
          <a-button
            icon="link"
            size="small"
            type="link"
            @click="$refs.modal.show(record)"
          >
            关联
          </a-button>
        </template>
      </a-table>

      <div style="height: 32px">
        <a-pagination
          v-model="form.page"
          :pageSize="form.pageSize"
          :show-size-changer="true"
          :show-total="t => `共 ${t} 条`"
          :total="total"
          @change="
          page => {
            this.form.page = page
            this.fetch()
          }
        "
          @showSizeChange="
          (_, pageSize) => {
            this.form.page = 1
            this.form.pageSize = pageSize
            this.fetch()
          }
        "
          style="float: right"
        ></a-pagination>
      </div>

      <os-drawer ref="osDrawer"></os-drawer>
      <link-modal ref="modal" :hypervisor-id="hypervisorId" @ok="fetch"></link-modal>
    </div>
  </div>
</template>

<script>
import { getHypervisorVirtualMachineList } from '@/api/hypervisor'
import ReloadButton from '@/components/button/ReloadButton'
import SettingButton from '@/components/button/SettingButton'
import LinkModal from '@/components/table/VirtualMachineTable/modules/LinkModal'

export default {
  name: 'VirtualMachineListTable',
  components: {
    LinkModal,
    'os-drawer': () => import('@/components/drawer/OSDrawer'),
    ReloadButton,
    SettingButton
  },
  props: {
    hypervisorId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      form: {
        name: '',
        page: 1,
        pageSize: 10
      },
      columnsAll: [
        {
          dataIndex: 'name',
          title: '名称',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'ip_address',
          title: 'IP 地址',
          width: 160
        },
        {
          dataIndex: 'cpu',
          title: 'CPU',
          align: 'center',
          width: 100,
          scopedSlots: {
            customRender: 'cpu'
          }
        },
        {
          dataIndex: 'memory',
          title: '内存',
          align: 'center',
          width: 100,
          scopedSlots: {
            customRender: 'memory'
          }
        },
        {
          dataIndex: 'os',
          title: '关联操作系统',
          width: 200,
          scopedSlots: {
            customRender: 'os'
          }
        },
        {
          dataIndex: 'created_at',
          title: '创建时间',
          width: 200
        },
        {
          dataIndex: 'updated_at',
          title: '更新时间',
          width: 200
        },
        {
          dataIndex: 'operation',
          title: '操作',
          align: 'center',
          fixed: 'right',
          width: 90,
          scopedSlots: {
            customRender: 'operation'
          }
        }
      ],
      columns: [],
      columnDefaultSelected: [
        'name',
        'ip_address',
        'cpu',
        'memory',
        'os',
        'updated_at',
        'operation'
      ],
      dataSource: [],
      total: 0,
      loading: false
    }
  },
  computed: {
    scrollX () {
      let sum = 0
      this.columns.forEach(column => {
        sum += column.width
      })
      return sum
    }
  },
  mounted () {
    this.columns = this.columnsAll.filter(column => this.columnDefaultSelected.indexOf(column.dataIndex) !== -1)
    this.fetch()
  },
  methods: {
    fetch () {
      const params = {
        page: this.form.page,
        page_size: this.form.pageSize
      }
      if (this.form.name) params.name = this.form.name
      this.loading = true
      getHypervisorVirtualMachineList(this.hypervisorId, params).then(res => {
        const data = res.data
        this.dataSource = data.data
        this.total = data.total * 1
      }).finally(() => {
        this.loading = false
      })
    },
    search (value) {
      this.form.name = value
      this.form.page = 1
      this.fetch()
    },
    updateColumns (columns) {
      this.columns = [...columns]
    }
  }
}
</script>

<style lang="less">
#virtual-machine-list-table-container {
  background: #fff;
  border-radius: 6px;
  padding: 24px 32px 20px 40px;

  header {
    height: 25px;
    font-size: 18px;
    font-weight: 500;
    color: rgba(0,0,0,0.85);
    line-height: 25px;
    margin-bottom: 24px;
    margin-left: -8px;
  }
  .list-table-search {
    width: 200px;
    .ant-input {
      background-color: #fff;
    }
  }
}
</style>
