<template>
  <div style="margin: 26px 0 0 0;  height: 240px;">
    <a-spin :spinning="spinning">
      <bar-chart
        v-if="chartData.length"
        :chart-data="chartData"
        chart-id="virtual-machine-storage-usage-chart"
      ></bar-chart>
      <div v-else style="height: 240px; position: relative; overflow: hidden;">
        <empty-component
          :imgUrl="require('@/assets/images/info_empty.png')"
          :body-style="{
            height: '120px',
            marginTop: '28px'
          }"
          :description-style="{
            marginTop: '-8px',
            marginRight: '8px'
          }"
          description="暂无数据"
        ></empty-component>
      </div>
    </a-spin>
  </div>
</template>

<script>
import BarChart from '@/components/chart/BarChart'
import { getHypervisorVirtualMachineTop } from '@/api/hypervisor'

export default {
  name: 'VirtualMachineDiskUsageTop',
  components: {
    EmptyComponent: () => import('@/components/EmptyComponent.vue'),
    BarChart
  },
  props: {
    hypervisorId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      chartData: [],
      spinning: false
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      this.spinning = true
      getHypervisorVirtualMachineTop(this.hypervisorId, {
        top_of: 'disk',
        limit: 5
      }).then(res => {
        this.chartData = res.data.data
      }).finally(() => { this.spinning = false })
    }
  }
}
</script>
