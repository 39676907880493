<template>
  <a-icon :component="component"></a-icon>
</template>

<script>
export default {
  name: 'HypervisorUnitIcon',
  props: {
    icon: {
      type: String,
      required: true
    }
  },
  computed: {
    component () {
      switch (this.icon) {
        case 'host':
          return {
            template: `
              <svg viewBox="0 0 1024 1024" width="1em" height="1em">
                <path d="M80.896 293.6832L455.3728 96.9728V0L0 239.8208v544.3584L455.3728 1024v-96.9728L80.896 730.2144V293.7856zM568.6272 0v96.9728L943.104 293.7856v436.4288L568.6272 927.0272V1024L1024 784.1792V239.8208z" fill="currentColor" p-id="3526"></path>
                <path d="M512 139.6736l-355.84 174.08L512 462.848l355.84-149.4016z m372.3264 220.0576L542.72 514.56v138.5472l342.016-160.256z m0 192.8192L542.72 707.3792v130.4576L884.3264 674.816V552.96zM481.28 514.56L139.6736 359.7312v133.12L481.28 650.3424V514.56z m-96.5632 59.8016a43.52 43.52 0 0 1-35.84-40.96 27.5456 27.5456 0 0 1 35.84-27.2384c19.3536 5.5296 35.84 21.8112 35.84 40.96a27.4432 27.4432 0 0 1-26.4192 28.4672 26.9312 26.9312 0 0 1-9.0112-1.2288zM139.6736 552.96v121.856L481.28 837.8368V707.3792z m245.76 211.8656a43.4176 43.4176 0 0 1-35.84-40.96A27.5456 27.5456 0 0 1 385.1264 696.32a46.1824 46.1824 0 0 1 35.84 40.96 27.4432 27.4432 0 0 1-26.4192 28.4672 26.9312 26.9312 0 0 1-9.4208-1.3312z" fill="currentColor" p-id="3527"></path>
              </svg>
            `
          }
        case 'virtualMachine':
          return {
            template: `
            <svg viewBox="0 0 1024 1024" width="1em" height="1em" style="width: 0.95em; height: 0.95em;">
                <path d="M916.288 0.768H39.68a37.376 37.376 0 0 0-38.592 38.656v115.904c0 20.352 16.256 38.656 38.592 38.656h876.672a38.592 38.592 0 0 0 38.656-38.656V39.424a38.592 38.592 0 0 0-38.656-38.656zM192.192 128.896H64.064V63.808h128.128v65.088z m730.24 126.08H33.536a32.128 32.128 0 0 0-32.512 32.64v128.064c0 18.304 14.208 32.576 32.512 32.576h890.88a32.128 32.128 0 0 0 32.576-32.576V287.552c-2.048-18.304-16.256-32.512-34.56-32.512zM192.192 383.232H64.064v-63.04h128.128v63.04zM64.064 889.6c0-111.872 105.728-174.912 168.832-187.072 12.16-2.048 22.4-12.224 24.384-26.496 6.08-46.72 32.512-93.568 97.6-101.696a29.184 29.184 0 0 0 26.496-30.464v-4.096a31.296 31.296 0 0 0-30.528-30.528H31.488a31.296 31.296 0 0 0-30.464 30.528v447.488c0 16.256 14.208 30.528 30.464 30.528h26.432c24.448 0 38.656-24.448 26.496-46.784-12.224-18.304-20.352-44.8-20.352-81.408z" fill="currentColor" p-id="8091"></path>
                <path d="M891.904 763.52c28.48-158.656-75.264-254.272-191.232-254.272-126.08 0-191.168 69.12-191.168 126.08-59.008 0-126.08 38.72-126.08 128.192-79.36 0-191.232 42.688-191.232 126.08 0 120.064 93.568 128.192 191.168 128.192h445.44c122.048 0 191.232-32.576 191.232-128.192-2.048-79.296-59.008-126.08-128.128-126.08z" fill="currentColor" p-id="8092"></path>
              </svg>
            `
          }
        case 'datastore':
          return {
            template: `
              <svg viewBox="0 0 1024 1024" width="1.5em" height="1.5em" style="width: 1.35em; height: 1.35em; margin-top: -6px;">
                <path d="M541.696 915.456c102.4-8.704 189.952-59.904 249.856-132.096 8.704 2.048 14.848 2.048 23.552 2.048 55.296 0 102.4-45.056 102.4-102.4 0-55.296-45.056-102.4-102.4-102.4-57.856 0-102.4 44.544-102.4 102.4 0 21.504 6.144 42.496 19.456 57.344-49.152 57.344-117.248 93.696-196.096 102.4-80.896 6.144-157.696-19.456-215.552-68.096-12.8 19.456-34.304 36.352-57.344 45.056 73.728 67.584 174.08 105.984 278.528 95.744z m275.456-282.112c27.648 0 51.2 23.552 51.2 51.2s-23.552 51.2-51.2 51.2-51.2-23.552-51.2-51.2 20.992-51.2 51.2-51.2zM512 309.248c31.744 0 61.952-14.848 78.848-38.4 113.152 31.744 200.704 132.096 210.944 256v10.752c4.096 0 8.704-2.048 10.752-2.048 21.504-2.048 40.448 2.048 59.904 10.752 0-8.704 0-16.896-2.048-23.552-12.8-153.6-121.344-277.504-260.096-317.952 0-55.296-45.056-98.304-98.304-98.304-55.296 0-102.4 45.056-102.4 102.4 0 55.808 47.104 100.352 102.4 100.352z m0-151.552c27.648 0 51.2 23.552 51.2 51.2s-23.552 51.2-51.2 51.2-51.2-23.552-51.2-51.2c0-29.696 23.552-51.2 51.2-51.2z m-405.504 527.36c0 55.296 45.056 102.4 102.4 102.4 55.296 0 102.4-45.056 102.4-102.4 0-51.2-38.4-93.696-89.6-100.352v-6.656C206.848 454.656 277.504 338.944 384 287.744c-12.8-16.896-21.504-38.4-23.552-61.952v-6.144c-136.704 61.952-226.304 204.8-210.944 362.496 0 6.144 2.048 10.752 2.048 16.896-27.648 21.504-45.056 51.712-45.056 86.016z m151.552 0c0 27.648-23.552 51.2-51.2 51.2s-51.2-23.552-51.2-51.2 23.552-51.2 51.2-51.2 51.2 23.04 51.2 51.2z" fill="currentColor" p-id="54212"></path>
                <path d="M328.704 571.904c-2.048-2.048-4.096 0-4.096 2.048v87.552s0 2.048 2.048 2.048l74.752 42.496h2.048l2.048-2.048v-87.552c0-2.048 0-2.048-2.048-2.048l-74.752-42.496z m97.792 57.344c-2.048-2.048-4.096 0-4.096 2.048v87.552s0 2.048 2.048 2.048l74.752 42.496h2.048l2.048-2.048v-87.552c0-2.048 0-2.048-2.048-2.048l-74.752-42.496z m275.456-53.248c0-2.048 0-2.048-2.048-2.048h-2.048l-74.752 42.496-2.048 2.048v87.552c0 2.048 2.048 4.096 4.096 2.048l74.752-42.496s0-2.048 2.048-2.048v-87.552z m-78.848 18.944l74.752-42.496s0-2.048 2.048-2.048V462.848c0-2.048 0-2.048-2.048-2.048h-2.048l-74.752 42.496-2.048 2.048v87.552c-0.512 2.048 1.536 4.608 4.096 2.048z m-21.504 34.304h-2.048l-74.752 42.496-2.048 2.048v87.552c0 2.048 2.048 4.096 4.096 2.048l74.752-42.496s0-2.048 2.048-2.048v-87.552c-2.048 0-2.048-2.048-2.048-2.048zM509.952 433.152h2.048l74.752-42.496 2.048-2.048s0-2.048-2.048-2.048L512 343.552h-2.048L435.2 386.048l-2.048 2.048c0 2.048 0 2.048 2.048 2.048l74.752 43.008z m80.896 66.048c0-2.048 0-2.048-2.048-2.048l-74.752-42.496h-2.048l-74.752 42.496-2.048 2.048c0 2.048 0 2.048 2.048 2.048l74.752 42.496h2.048l74.752-42.496c2.048 0 2.048 0 2.048-2.048zM492.544 443.904c0-2.048 0-2.048-2.048-2.048L415.744 399.36h-2.048l-74.752 42.496-2.048 2.048c0 2.048 0 2.048 2.048 2.048l74.752 42.496h2.048l74.752-42.496c2.048 0 2.048-2.048 2.048-2.048z m196.608 0c0-2.048 0-2.048-2.048-2.048L612.352 399.36h-2.048l-74.752 42.496-2.048 2.048c0 2.048 0 2.048 2.048 2.048l74.752 42.496h2.048l74.752-42.496c2.048 0 2.048-2.048 2.048-2.048z m-189.952 208.896c2.048 2.048 4.096 0 4.096-2.048V563.2c0-2.048 0-2.048-2.048-2.048l-74.752-44.544h-4.096v91.648s0 2.048 2.048 2.048l74.752 42.496zM328.704 458.752h-2.048v89.6s0 2.048 2.048 2.048l74.752 42.496h4.096V501.248s0-2.048-2.048-2.048l-76.8-40.448z m194.048 194.048h2.048l74.752-42.496 2.048-2.048v-87.552c0-2.048-2.048-4.096-4.096-2.048l-76.8 44.544v89.6c0-2.048 0-2.048 2.048 0z" fill="currentColor" p-id="54213"></path>
              </svg>
            `
          }
        case 'networkInterface':
          return {
            template: `
            <svg viewBox="0 0 1024 1024" width="1em" height="1em">
              <path d="M810.6 673.4v246.3H213.5V673.4h597.1m39.8-39.8H173.7v325.9h676.7V633.6z" p-id="35454"></path>
              <path d="M273.2 723.8H313v145.6h-39.8zM346.2 723.8H386v145.6h-39.8zM419.2 723.8H459v145.6h-39.8zM492.2 723.8H532v145.6h-39.8zM638.1 723.8h39.8v145.6h-39.8zM565.1 723.8h39.8v145.6h-39.8zM711.1 723.8h39.8v145.6h-39.8zM64.2 63.9v608.2h895.7V63.9H64.2z m475.9 437.2c-15.5 15.5-40.7 15.5-56.2 0s-15.5-40.7 0-56.2 40.7-15.5 56.2 0 15.5 40.7 0 56.2z m63.2-78.1c-3.9 3.9-9 5.8-14.1 5.8s-10.2-1.9-14.1-5.8c-16.8-16.8-39.2-26.1-63.1-26.1-23.8 0-46.2 9.3-63.1 26.1-7.8 7.8-20.4 7.8-28.1 0-7.8-7.8-7.8-20.4 0-28.1 24.4-24.4 56.8-37.8 91.2-37.8s66.9 13.4 91.2 37.8c7.9 7.7 7.9 20.3 0.1 28.1z m54.2-45.9c-3.9 3.9-9 5.8-14.1 5.8s-10.2-1.9-14.1-5.8c-31.3-31.3-73-48.6-117.2-48.6s-85.9 17.2-117.2 48.6c-7.8 7.8-20.4 7.8-28.2 0-7.8-7.8-7.8-20.4 0-28.2 38.8-38.8 90.5-60.2 145.4-60.2 54.9 0 106.6 21.4 145.4 60.2 7.7 7.8 7.7 20.4 0 28.2z m40.4-48.8c-3.9 3.9-9 5.8-14.1 5.8s-10.2-1.9-14.1-5.8C627.6 286.2 571.6 263 512 263s-115.6 23.2-157.7 65.3c-7.8 7.8-20.4 7.8-28.2 0-7.8-7.8-7.8-20.4 0-28.2 49.6-49.6 115.7-77 185.9-77s136.2 27.3 185.9 77c7.8 7.9 7.8 20.5 0 28.2z" p-id="35455"></path>
            </svg>
            `
          }
        case 'cpu':
          return {
            template: `
              <svg viewBox="0 0 1024 1024" width="1em" height="1em">
                <path d="M794.392 187.692H243.708c-31.064 0-56.48 25.417-56.48 56.48v550.685c0 31.064 25.416 56.48 56.48 56.48h550.685c31.064 0 56.48-25.417 56.48-56.48V244.172c0-31.063-25.416-56.48-56.481-56.48z m-56.48 465.964c0 46.718-38.003 84.721-84.721 84.721H384.909c-46.718 0-84.721-38.003-84.721-84.721V385.374c0-46.718 38.003-84.721 84.721-84.721h268.282c46.718 0 84.721 38.003 84.721 84.721v268.282z" p-id="55313"></path>
                <path d="M653.191 357.133H384.909c-15.575 0-28.24 12.672-28.24 28.24v268.282c0 15.568 12.665 28.24 28.24 28.24h268.282c15.575 0 28.24-12.672 28.24-28.24V385.374c0-15.568-12.665-28.241-28.24-28.241zM372.431 95.09c0-17.046-13.821-30.867-30.867-30.867s-30.867 13.821-30.867 30.867v69.451h61.734V95.09zM63.759 527.232c0 17.046 13.821 30.867 30.867 30.867h69.451v-61.734H94.626c-17.047-0.001-30.867 13.82-30.867 30.867zM542.201 95.09c0-17.046-13.821-30.867-30.867-30.867s-30.867 13.821-30.867 30.867v69.451h61.734V95.09zM711.97 95.09c0-17.046-13.821-30.867-30.867-30.867s-30.867 13.821-30.867 30.867v69.451h61.734V95.09zM958.908 357.462c0-17.046-13.821-30.867-30.867-30.867h-54.018v61.734h54.018c17.046 0 30.867-13.821 30.867-30.867zM63.759 357.462c0 17.046 13.821 30.867 30.867 30.867h69.451v-61.734H94.626c-17.047 0-30.867 13.82-30.867 30.867zM928.041 666.134h-54.018v61.734h54.018c17.046 0 30.867-13.821 30.867-30.867s-13.821-30.867-30.867-30.867zM928.041 496.364h-54.018v61.734h54.018c17.046 0 30.867-13.821 30.867-30.867s-13.821-30.867-30.867-30.867zM650.236 928.505c0 17.046 13.821 30.867 30.867 30.867s30.867-13.821 30.867-30.867v-54.018h-61.734v54.018zM310.696 928.505c0 17.046 13.821 30.867 30.867 30.867s30.867-13.821 30.867-30.867v-54.018h-61.734v54.018zM480.466 928.505c0 17.046 13.821 30.867 30.867 30.867s30.867-13.821 30.867-30.867v-54.018h-61.734v54.018zM63.759 697.001c0 17.046 13.821 30.867 30.867 30.867h69.451v-61.734H94.626c-17.047 0-30.867 13.821-30.867 30.867z" p-id="55314"></path>
              </svg>
            `
          }
        case 'memory':
          return {
            template: `
              <svg viewBox="0 0 1024 1024" width="1em" height="1em">
                <path d="M704 800h-64v80c0 8.837-7.163 16-16 16h-32c-8.837 0-16-7.163-16-16v-80h-64v80c0 8.837-7.163 16-16 16h-32c-8.837 0-16-7.163-16-16v-80h-64v80c0 8.837-7.163 16-16 16h-32c-8.837 0-16-7.163-16-16v-80H192v64c0 17.673-14.327 32-32 32H96c-17.673 0-32-14.327-32-32V320c17.673 0 32-14.327 32-32 0-17.673-14.327-32-32-32v-96c0-17.673 14.327-32 32-32h832c17.673 0 32 14.327 32 32v96c-17.673 0-32 14.327-32 32 0 17.673 14.327 32 32 32v544c0 17.673-14.327 32-32 32h-64c-17.673 0-32-14.327-32-32v-64h-64v80c0 8.837-7.163 16-16 16h-32c-8.837 0-16-7.163-16-16v-80zM192 224c-17.673 0-32 14.327-32 32v320c21.333-1.266 32-1.266 32 0v64c0 17.673 14.327 32 32 32h576c17.673 0 32-14.327 32-32v-63.027c0-1.213 10.667-1.213 32 0V256c0-17.673-14.327-32-32-32H192z m64 63.998h512c17.673 0 32 14.327 32 32V512h-32v64c0 17.673-14.327 32-32 32H288c-17.673 0-32-14.327-32-32v-64h-32V319.998c0-17.673 14.327-32 32-32zM640 352c-17.673 0-32 14.327-32 32v95.998c0 17.673 14.327 32 32 32 17.673 0 32-14.327 32-32V384c0-17.673-14.327-32-32-32z" fill="currentColor" p-id="57333"></path>
              </svg>
            `
          }
        case 'storage':
          return {
            template: `
              <svg viewBox="0 0 1024 1024" width="1em" height="1em">
                <path d="M917.97014938 213.4925375C917.97014938 113.19402969 736.47761188 32 512 32S106.02985062 113.19402969 106.02985062 213.4925375v23.88059719c14.32835812 42.98507438 57.3134325 76.41791063 119.40298501 100.29850687 0 0 4.77611906 0 4.77612 4.77612 4.77611906 4.77611906 14.32835812 4.77611906 19.10447718 9.55223813 4.77611906 0 9.55223906 4.77611906 19.10447719 4.77612 4.77611906 0 4.77611906 0 9.55223906 4.77611906 4.77611906 0 14.32835812 4.77611906 19.10447813 4.77611906h9.55223812c9.55223906 4.77611906 19.10447719 4.77611906 33.43283625 9.55223907 9.55223906 0 19.10447719 4.77611906 28.65671625 4.77612h4.77611907c9.55223906 0 19.10447719 4.77611906 28.65671718 4.77611906h9.55223813c9.55223906 0 19.10447719 0 28.65671719 4.77611906H512c200.59701469 0 367.76119406-62.0895525 405.97014938-148.05970125v-14.32835813-14.32835812z" fill="currentColor" p-id="60170"></path>
                <path d="M512 581.25373156c205.37313469 0 372.53731312-66.86567156 405.97014938-152.83582125V294.68656719c-38.20895531 85.97014969-205.37313469 148.05970125-405.97014938 148.05970125-205.37313469 0-372.53731312-66.86567156-405.97014938-152.83582031v143.28358218c38.20895531 85.97014969 205.37313469 148.05970125 405.97014938 148.05970125z" fill="currentColor" p-id="60171"></path>
                <path d="M512 767.52238812c205.37313469 0 372.53731312-66.86567156 405.97014938-152.83582125V476.17910469c-33.43283625 85.97014969-205.37313469 152.83582125-405.97014938 152.83582031s-367.76119406-62.0895525-405.97014938-148.05970125v138.50746312c38.20895531 85.97014969 205.37313469 148.05970125 405.97014938 148.05970125z" fill="currentColor" p-id="60172"></path>
                <path d="M512 820.05970156c-200.59701469 0-367.76119406-62.0895525-405.97014938-148.05970125V805.73134344c0 100.29850781 181.4925375 186.26865656 405.97014938 186.26865656s405.97014938-81.19402969 405.97014938-186.26865656V667.22388031c-33.43283625 85.97014969-200.59701469 152.83582125-405.97014938 152.83582125z" fill="currentColor" p-id="60173"></path>
              </svg>
            `
          }
        default:
          return {
            template: `
              <svg viewBox="0 0 1024 1024" width="1em" height="1em">
                <path d="M80.896 293.6832L455.3728 96.9728V0L0 239.8208v544.3584L455.3728 1024v-96.9728L80.896 730.2144V293.7856zM568.6272 0v96.9728L943.104 293.7856v436.4288L568.6272 927.0272V1024L1024 784.1792V239.8208z" fill="currentColor" p-id="3526"></path>
                <path d="M512 139.6736l-355.84 174.08L512 462.848l355.84-149.4016z m372.3264 220.0576L542.72 514.56v138.5472l342.016-160.256z m0 192.8192L542.72 707.3792v130.4576L884.3264 674.816V552.96zM481.28 514.56L139.6736 359.7312v133.12L481.28 650.3424V514.56z m-96.5632 59.8016a43.52 43.52 0 0 1-35.84-40.96 27.5456 27.5456 0 0 1 35.84-27.2384c19.3536 5.5296 35.84 21.8112 35.84 40.96a27.4432 27.4432 0 0 1-26.4192 28.4672 26.9312 26.9312 0 0 1-9.0112-1.2288zM139.6736 552.96v121.856L481.28 837.8368V707.3792z m245.76 211.8656a43.4176 43.4176 0 0 1-35.84-40.96A27.5456 27.5456 0 0 1 385.1264 696.32a46.1824 46.1824 0 0 1 35.84 40.96 27.4432 27.4432 0 0 1-26.4192 28.4672 26.9312 26.9312 0 0 1-9.4208-1.3312z" fill="currentColor" p-id="3527"></path>
              </svg>
            `
          }
      }
    }
  }
}
</script>
