<template>
  <div :id="chartId">
    <a-empty
      v-if="!chartData.length"
      :description="false"
      :style="{ height: height + 'px', lineHeight: height + 'px' }"
    ></a-empty>
  </div>
</template>

<script>
import { Chart } from '@antv/g2'
import { chartColors } from '@/utils/const'
import { humanizeTime, humanizeValue } from '@/utils'

const chart = {}

export default {
  name: 'BarChart',
  props: {
    chartData: {
      type: Array,
      default: () => []
    },
    chartId: {
      type: String,
      required: true
    },
    colors: {
      type: [Array, Function],
      default: () => chartColors
    },
    height: {
      type: Number,
      default: 240
    },
    legend: {
      type: [Object, Boolean],
      default: () => {
        return {
          position: 'bottom'
        }
      }
    },
    showLabel: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: [Object, Boolean],
      default: () => {
        return {
          shared: true,
          showMarkers: false,
          showTitle: false
        }
      }
    },
    transpose: {
      type: Boolean,
      default: false
    },
    x: {
      type: String,
      default: 'name'
    },
    xAxis: {
      type: [Object, Boolean],
      default: () => {
        return {}
      }
    },
    y: {
      type: String,
      default: 'value'
    },
    yAxis: {
      type: [Object, Boolean],
      default: () => {
        return {}
      }
    },
    format: {
      type: String,
      default: 'default'
    },
    size: {
      type: Number,
      default: 20
    },
    reverse: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    humanizeFunc () {
      return this.format === 'time' ? humanizeTime : humanizeValue
    }
  },
  mounted () {
    chart[this.chartId] = new Chart({
      container: this.chartId,
      autoFit: true,
      height: this.height
    })
    chart[this.chartId].scale(this.y, {
      min: 0,
      nice: true,
      formatter: value =>
        this.format === 'time'
          ? this.humanizeFunc(value, true)
          : this.humanizeFunc(value)
    })
    chart[this.chartId].tooltip(this.tooltip)
    chart[this.chartId].legend(this.legend)
    if (this.transpose) {
      chart[this.chartId].coordinate().transpose()
    }
    chart[this.chartId].axis(this.x, this.xAxis)
    chart[this.chartId].axis(this.y, this.yAxis)
    if (this.showLabel) {
      chart[this.chartId].appendPadding = this.transpose
        ? [0, 40, 0, 0]
        : [20, 0, 0, 0]
      chart[this.chartId]
        .interval()
        .size(20)
        .position(`${this.x}*${this.y}`)
        // .style({
        //   radius: [8, 8, 0, 0]
        // })
        .label(this.y, {
          style: {
            fill: 'rgba(0,0,0,.45)'
          },
          position: 'right',
          offsetX: -6
        })
        .color(this.x, this.colors)
    } else {
      chart[this.chartId]
        .interval()
        .size(20)
        .position(`${this.x}*${this.y}`)
        // .style({
        //   radius: [8, 8, 0, 0]
        // })
        .color(this.x, this.colors)
    }
    this.render()
    this.$forceUpdate()
  },
  methods: {
    render () {
      if (this.chartData.length === 0) {
        chart[this.chartId].changeVisible(false)
      } else {
        if (this.reverse) {
          const data = JSON.parse(JSON.stringify(this.chartData))
          chart[this.chartId].changeData(data.reverse())
        } else chart[this.chartId].changeData(this.chartData)
        chart[this.chartId].changeVisible(true)
      }
    }
  },
  watch: {
    chartData (v, ov) {
      this.render()
    }
  }
}
</script>
