<template>
  <div id="datastores-list-table-container">
    <div
      style="
        height: 32px;
        margin-bottom: 16px;
        font-size: 14px;
        line-height: 32px;
      "
    >
      <a-space size="middle" style="float: right">
        <a-input-search
          placeholder="请输入关键词"
          @search="search"
        ></a-input-search>
        <reload-button @click="fetch"></reload-button>

        <setting-button
          :columns="columnsAll"
          :default-selected="columnDefaultSelected"
          @selected="updateColumns"
        ></setting-button>
      </a-space>
    </div>

    <div class="custom-table">
      <a-table
        :columns="columns"
        :data-source="dataSource"
        :loading="loading"
        :pagination="false"
        row-key="id"
        :scroll="{ x: scrollX }"
        style="margin-bottom: 16px"
      >
        <template slot="name" slot-scope="text">
          <a-tooltip placement="topLeft" :title="text">{{ text }}</a-tooltip>
        </template>
        <template slot="size" slot-scope="record">
          {{ `${record} GB` }}
        </template>
        <template slot="usage" slot-scope="record">
          <usage-progress :percent="record" style="width: 200px"></usage-progress>
        </template>
      </a-table>

      <div style="height: 32px">
        <a-pagination
          v-model="form.page"
          :pageSize="form.pageSize"
          :show-size-changer="true"
          :show-total="t => `共 ${t} 条`"
          :total="total"
          @change="
          page => {
            this.form.page = page
            this.fetch()
          }
        "
          @showSizeChange="
          (_, pageSize) => {
            this.form.page = 1
            this.form.pageSize = pageSize
            this.fetch()
          }
        "
          style="float: right"
        ></a-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getHypervisorDatastoreList } from '@/api/hypervisor'
import ReloadButton from '@/components/button/ReloadButton'
import SettingButton from '@/components/button/SettingButton'
import UsageProgress from '@/components/progress/UsageProgress'

export default {
  name: 'DatastoreListTable',
  components: {
    ReloadButton,
    SettingButton,
    UsageProgress
  },
  props: {
    hypervisorId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      form: {
        name: '',
        page: 1,
        pageSize: 10
      },
      columnsAll: [
        {
          dataIndex: 'name',
          title: '名称',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'size',
          title: '大小',
          scopedSlots: {
            customRender: 'size'
          }
        },
        {
          dataIndex: 'usage',
          title: '使用率',
          scopedSlots: {
            customRender: 'usage'
          }
        },
        {
          dataIndex: 'created_at',
          title: '创建时间',
          width: 200
        },
        {
          dataIndex: 'updated_at',
          title: '更新时间',
          width: 200
        }
      ],
      columns: [],
      columnDefaultSelected: [
        'name',
        'size',
        'usage',
        'updated_at'
      ],
      dataSource: [],
      total: 0,
      loading: false
    }
  },
  computed: {
    scrollX () {
      let sum = 0
      this.columns.forEach(column => {
        sum += column.width
      })
      return sum
    }
  },
  mounted () {
    this.columns = this.columnsAll.filter(column => this.columnDefaultSelected.indexOf(column.dataIndex) !== -1)
    this.fetch()
  },
  methods: {
    fetch () {
      const params = {
        page: this.form.page,
        page_size: this.form.pageSize
      }
      if (this.form.name) params.name = this.form.name
      this.loading = true
      getHypervisorDatastoreList(this.hypervisorId, params).then(res => {
        const data = res.data
        this.dataSource = data.data
        this.total = data.total * 1
      }).finally(() => {
        this.loading = false
      })
    },
    search (value) {
      this.form.name = value
      this.form.page = 1
      this.fetch()
    },
    updateColumns (columns) {
      this.columns = [...columns]
    }
  }
}
</script>

<style lang="less">
#datastores-list-table-container {
  background: #fff;
  border-radius: 6px;
  padding: 24px 32px 20px 40px;

  header {
    height: 25px;
    font-size: 18px;
    font-weight: 500;
    color: rgba(0,0,0,0.85);
    line-height: 25px;
    margin-bottom: 24px;
    margin-left: -8px;
  }
  .list-table-search {
    width: 200px;
    .ant-input {
      background-color: #fff;
    }
  }
}
</style>
