<template>
  <a-select
    :allow-clear="allowClear"
    :disabled="disabled"
    :filter-option="false"
    :label-in-value="true"
    placeholder="请选择资源"
    :mode="mode"
    :showArrow="showArrow"
    :options="options"
    :show-search="true"
    :value="selected"
    @change="change"
    @popupScroll="popupScroll"
    @search="search"
  ></a-select>
</template>

<script>
import { getApplicationList } from '@/api/application'
import { getDatabaseList } from '@/api/database'
import { getDomainList } from '@/api/domain'
import { getMiddlewareList } from '@/api/middleware'
import { getNetworkDeviceList } from '@/api/network-device'
import { getOSList } from '@/api/os'
import { getServerList } from '@/api/server'
import { getSiteList } from '@/api/site'
import { getSSLCertificateList } from '@/api/ssl-certificate'
import { getStorageList } from '@/api/storage'

export default {
  name: 'SourceSelect',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'default'
    },
    sourceType: {
      type: String,
      default: 'unknown'
    },
    value: {
      type: [Array, Object]
    },
    showArrow: {
      type: Boolean,
      default: true
    },
    allowClear: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      form: {
        page: 1,
        pageSize: 20
      },
      selected: undefined,
      options: [],
      total: 0,
      timer: null
    }
  },
  computed: {
    getListFunc () {
      switch (this.sourceType) {
        case 'network_device':
          return getNetworkDeviceList
        case 'storage':
          return getStorageList
        case 'server':
          return getServerList
        case 'os':
          return getOSList
        case 'middleware':
          return getMiddlewareList
        case 'database':
          return getDatabaseList
        case 'application':
          return getApplicationList
        case 'domain':
          return getDomainList
        case 'site':
          return getSiteList
        case 'ssl_certificate':
          return getSSLCertificateList
        default:
          return undefined
      }
    }
  },
  mounted () {
    this.selected = this.value ? this.formatValue(this.value) : undefined
    this.fetch()
  },
  methods: {
    fetch () {
      if (this.getListFunc) {
        const params = {
          page: this.form.page,
          page_size: this.form.pageSize
        }
        if (this.form.name) params.name = this.form.name
        this.getListFunc(params).then(res => {
          const data = res.data
          this.total = data.total
          data.data.forEach(source => {
            if (
              this.options.filter(option => option.value === source.id)
                .length === 0
            ) {
              this.options.push({
                key: source.id,
                label: source.name
              })
            }
          })
        })
      }
    },
    formatValue (value) {
      if (this.mode === 'multiple') {
        return value.map(item => {
          return {
            key: item.id,
            label: item.name
          }
        })
      } else {
        return {
          key: value.id,
          label: value.name
        }
      }
    },
    change (value) {
      if (value) {
        if (this.mode === 'multiple') {
          this.$emit(
            'input',
            value.map(item => {
              return {
                id: item.key,
                name: item.label
              }
            })
          )
        } else {
          this.$emit('input', {
            id: value.key,
            name: value.label
          })
        }
      } else this.$emit('input', undefined)
    },
    popupScroll (e) {
      const { scrollHeight, scrollTop, clientHeight } = e.target
      if (scrollHeight - scrollTop === clientHeight) {
        if (this.total > this.form.page * this.form.pageSize) {
          this.form.page += 1
          this.fetch()
        }
      }
    },
    search (value) {
      this.form.name = value
      if (this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.options = []
        this.fetch()
      }, 500)
    }
  },
  watch: {
    sourceType () {
      this.fetch()
      this.change([])
    },
    value: {
      handler (v) {
        this.selected = v ? this.formatValue(v) : undefined
      },
      deep: true
    }
  }
}
</script>
