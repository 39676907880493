<template>
  <div class="overview-count-card" @click="$emit('troggleTab', item.key)">
    <div class="decoration" :style="{ background: color }"></div>

    <div class="main-container">
      <hypervisor-unit-icon
        :icon="item.key"
        :style="{
          color: color,
          fontSize: '48px',
          paddingRight: '40px'
        }"
      ></hypervisor-unit-icon>
      <div style="padding-right: 40px">
        <p class="card-value" :style="{ color: color }">{{ item.value }}</p>
        <p class="card-title">{{ item.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import HypervisorUnitIcon from '@/components/icon/HypervisorUnitIcon'

export default {
  name: 'CountCard',
  props: {
    item: {
      type: Object
    }
  },
  components: {
    HypervisorUnitIcon
  },
  computed: {
    color () {
      switch (this.item.key) {
        case 'host':
          return '#096dd9'
        case 'virtual_machine':
          return '#47d7b6'
        case 'datastore':
          return '#f7b500'
        case 'network_interface':
          return '#2591ff'
        default:
          return '#096dd9'
      }
    }
  }
}
</script>

<style scoped>
p {
  margin: 0;
  text-align: center;
}
.overview-count-card {
  height: 120px;
  background: #fff;
  border-radius: 11px;
  overflow: hidden;
}
.decoration {
  height: 10px;
}
.main-container {
  display: flex;
  height: 110px;
  align-items: center;
  justify-content: center;
}
.card-title {
  font-size: 18px;
  color: rgba(0,0,0,0.85);
  line-height: 25px;
}
.card-value {
  font-size: 30px;
  font-weight: 500;
  line-height: 42px;
}
</style>
